import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEO } from "src/components/seo"
import { Carousel } from "src/components/carousel"
import { Mobile } from "src/components/device"
import { Browser } from "src/components/device"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  Cell,
  Measure,
  HorizontalRuler,
  Headline,
  Paragraph,
  HeadlineHero,
  CellVerticalOffset,
} from "src/components/primitives"

import { useLightTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      subtitle: string
      intro: string
      global_title: string
      global_copy: string
      brand_title: string
      brand_copy: string
      motion_copy: string
      touchpoints_title: string
      touchpoints_copy: string
      dataviz_copy: string
      collateral_copy: string
      hub_title: string
      hub_copy: string
      expertise: string
    }
    images: {
      hero_static: any
      map_static: any
      grid_1_languages_static: any
      grid_1_logo_static: any
      grid_1_icons_static: any
      grid_1_color_static: any
      motion_static: any
      grid_2_socialmontage_static: any
      grid_2_social_1_static: any
      grid_2_social_2_static: any
      grid_2_powerpoint_static: any
      grid_2_stationery_static: any
      grid_3_infographic_static: any
      grid_3_book_static: any
      grid_3_brochure_static: any
      grid_3_factsheet_static: any
      grid_4_teamsbackground_static: any
      grid_4_slides_01: any
      grid_4_slides_02: any
      grid_4_slides_03: any
      brandhub_static: any
    }
    videos: {
      hero: any
      map: any
      grid_1_languages: any
      grid_1_logo: any
      grid_1_icons: any
      grid_1_color: any
      motion: any
      grid_2_social_1: any
      grid_2_social_2: any
      grid_3_infographic: any
      brandhub: any
    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, images, videos } = data.project

  useLightTheme()
  

  return (
    <article aria-label={copy.title}>
      <Helmet
        bodyAttributes={{
          class: "theme-light",
        }}
      />
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      <Container>
        <HeaderHero>
          <HeadlineHero>
            {copy.title}
            <span>{copy.subtitle}</span>
          </HeadlineHero>
        </HeaderHero>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive 
            video={videos.hero} 
            image={images.hero_static} 
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.intro}</Paragraph>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.global_title}</Headline>
          <Paragraph>{copy.global_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive 
            video={videos.map} 
            image={images.map_static} 
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.brand_title}</Headline>
          <Paragraph>{copy.brand_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid columns={{ bp1: "$2" }}>
            <VideoLoopResponsive
              ratio="$1/1"
              video={videos.grid_1_languages}
              image={images.grid_1_languages_static}
            />
            <VideoLoopResponsive
              ratio="$1/1"
              video={videos.grid_1_logo}
              image={images.grid_1_logo_static}
            />
            <VideoLoopResponsive
              ratio="$1/1"
              video={videos.grid_1_icons}
              image={images.grid_1_icons_static}
            />
            <VideoLoopResponsive
              ratio="$1/1"
              video={videos.grid_1_color}
              image={images.grid_1_color_static}
            />
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <Paragraph>{copy.motion_copy}</Paragraph>
      </Container>

      <Container>
        <Block>
          <VideoLoopResponsive 
            video={videos.motion} 
            image={images.motion_static} 
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.touchpoints_title}</Headline>
          <Paragraph>{copy.touchpoints_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2", bp3: "$2" }}>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_2_socialmontage_static} />
            </Col>
            <Col>
              <Cell
                align={{ bp0: "$center" }}
                style={{ backgroundColor: "#F4F4F4" }}
              >
                <Measure measure={{ bp3: "$4/5" }}>
                  <Mobile screen="#FFFFFF">
                    <VideoLoopResponsive
                      ratio="$9/16"
                      video={videos.grid_2_social_1}
                      image={images.grid_2_social_1_static}
                    />
                  </Mobile>
                </Measure>
              </Cell>
            </Col>
            <Col>
              <Cell
                align={{ bp0: "$center" }}
                style={{ backgroundColor: "#F4F4F4" }}
              >
                <Measure measure={{ bp3: "$4/5" }}>
                  <Mobile screen="#FFFFFF">
                    <VideoLoopResponsive
                      ratio="$9/16"
                      video={videos.grid_2_social_2}
                      image={images.grid_2_social_2_static}
                    />
                  </Mobile>
                </Measure>
              </Cell>
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_2_powerpoint_static} />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_2_stationery_static} />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <Paragraph>{copy.dataviz_copy}</Paragraph>
      </Container>

       <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <VideoLoopResponsive
                ratio="$16/9"
                video={videos.grid_3_infographic}
                image={images.grid_3_infographic_static}
              />
            </Col>
            <Col>
              <ImgFluid image={images.grid_3_book_static} />
            </Col>
            <Col>
              <ImgFluid image={images.grid_3_brochure_static} />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
              >
              <ImgFluid image={images.grid_3_factsheet_static} />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <Paragraph>{copy.collateral_copy}</Paragraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col>
              <ImgFluid 
                ratio="$3/4"
                image={images.grid_4_teamsbackground_static}
              />
            </Col>
            <Col>
              <Carousel
                ratio="$3/4"
                images={[
                  images.grid_4_slides_01,
                  images.grid_4_slides_02,
                  images.grid_4_slides_03,
                ]}
              />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.hub_title}</Headline>
          <Paragraph>{copy.hub_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Cell size="$large" style={{ backgroundColor: "#F4F4F4" }}>
            <Browser screen="#FFFFFF">
              <VideoLoopResponsive
                ratio="$16/9"
                video={videos.brandhub}
                image={images.brandhub_static}
              />
            </Browser>
          </Cell>
        </Block>
      </Container>

      <Container>
        <CaseStudyCapabilities>{copy.expertise}</CaseStudyCapabilities>
      </Container>
    </article>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "bmgf" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        subtitle
        intro
        global_title
        global_copy
        brand_title
        brand_copy
        motion_copy
        touchpoints_title
        touchpoints_copy
        dataviz_copy
        collateral_copy
        hub_title
        hub_copy
        expertise
      }
      images {
        hero_static {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        map_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_1_languages_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_logo_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_icons_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_color_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        motion_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_2_socialmontage_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_2_social_1_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_social_2_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_powerpoint_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_2_stationery_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_infographic_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_book_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_brochure_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_factsheet_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_4_teamsbackground_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_4_slides_01 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_4_slides_02 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_4_slides_03 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        brandhub_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
      }
      videos {
        hero {
          src {
            src_large
            src_medium
            src_small
          }
        }
        map {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_1_languages {
          src
        }
        grid_1_logo {
          src
        }
        grid_1_icons {
          src
        }
        grid_1_color {
          src
        }
        motion {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_2_social_1 {
          src
        }
        grid_2_social_2 {
          src
        }
        grid_3_infographic {
          src {
            src_large
            src_medium
            src_small
          }
        }
        brandhub {
          src {
            src_large
            src_medium
            src_small
          }
        }
      }
    }
  }
`
